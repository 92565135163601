import {gsap} from 'gsap'
import React, {useEffect} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {useLocation} from 'react-router-dom/cjs/react-router-dom.min'
import styled from 'styled-components'
import {hover} from '../styles/globalStyleVars'
import {Img} from './home/ImgP'

const SpecialtyLube = ({
                           offset,
                           hideCard,
                           title,
                           subtitle,
                           hideBtn,
                           bgImage,
                           call
                       }) => {
    const location = useLocation()

    useEffect(() => {
        // Use GSAP to animate opacity from 0 to 1
        gsap.to('.hide', {
            alpha: 1,
            visibility: 'visible',
            display: 'block',
            duration: 0.5,
            delay: 1,
        })
    }, [location.pathname]) // Run this effect only once when the component mounts

    return (
        <>
            <StyledLube offset={offset} className="specialty-lube ">
                {bgImage && (
                    <Img
                        src={bgImage}
                        alt={'Elite Tinting'}
                        objectFit={'cover'}
                        layout={'fill'}
                    />
                )}
                <Container>
                    <Row>
                        <Col sm={10}>
                            {title && <h1>{title}</h1>}
                            {subtitle && <h2 className={'split-up'}>{subtitle}</h2>}

                            {!hideBtn && (
                                <div className="call hover">
                                    <a href={`tel:+${call}`}>
                                        <svg
                                            id="Button_-_Hotline_-_Light"
                                            data-name="Button - Hotline - Light"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="170"
                                            height="44"
                                            viewBox="0 0 170 44"
                                        >
                                            <g
                                                id="Rectangle_492"
                                                data-name="Rectangle 492"
                                                fill="none"
                                                stroke="#fff"
                                                stroke-width="1"
                                                opacity="0.3"
                                            >
                                                <rect width="120" height="44" rx="22" stroke="none"/>
                                                <rect
                                                    x="0.5"
                                                    y="0.5"
                                                    width="169"
                                                    height="43"
                                                    rx="21.5"
                                                    fill="none"
                                                />
                                            </g>
                                            <g
                                                id="Rectangle_493"
                                                data-name="Rectangle 493"
                                                fill="none"
                                                stroke="#fff"
                                                stroke-width="2"
                                                strokeDasharray="1 520"
                                                opacity="0"
                                            >
                                                <rect width="120" height="44" rx="22" stroke="none"/>
                                                <rect
                                                    x="0.5"
                                                    y="0.5"
                                                    width="169"
                                                    height="43"
                                                    rx="21.5"
                                                    fill="none"
                                                />
                                            </g>
                                            <g
                                                id="Group_4811"
                                                data-name="Group 4811"
                                                transform="translate(-791 -27)"
                                            >
                                                <text
                                                    id="_16634"
                                                    data-name="16634"
                                                    transform="translate(843 54)"
                                                    fill="#fff"
                                                    font-size="16"
                                                    font-family="Avenir-Medium, Avenir"
                                                    font-weight="500"
                                                >
                                                    <tspan x="0" y="0">
                                                        {call}
                                                    </tspan>
                                                </text>
                                                <path
                                                    id="Path_8647"
                                                    data-name="Path 8647"
                                                    d="M4.965,13.295a21.287,21.287,0,0,0,7.084,5.528,11.057,11.057,0,0,0,3.964,1.169c.1,0,.187.008.283.008a3.289,3.289,0,0,0,2.546-1.09.09.09,0,0,0,.017-.021,10.7,10.7,0,0,1,.8-.829c.2-.186.4-.381.587-.58a2,2,0,0,0-.008-2.98l-2.5-2.491a2.027,2.027,0,0,0-1.464-.671,2.1,2.1,0,0,0-1.481.667l-1.489,1.484c-.137-.079-.279-.149-.412-.215a5.15,5.15,0,0,1-.458-.249A15.74,15.74,0,0,1,8.671,9.61,9.037,9.037,0,0,1,7.4,7.588c.391-.352.757-.721,1.111-1.082.125-.128.254-.257.383-.385a2.121,2.121,0,0,0,.691-1.492,2.1,2.1,0,0,0-.691-1.492L7.652,1.9c-.146-.145-.283-.286-.424-.431C6.953,1.189,6.666.9,6.383.638A2.078,2.078,0,0,0,4.919,0,2.139,2.139,0,0,0,3.438.642L1.882,2.192A3.179,3.179,0,0,0,.929,4.231a7.644,7.644,0,0,0,.578,3.315A19.273,19.273,0,0,0,4.965,13.295ZM1.944,4.318a2.189,2.189,0,0,1,.661-1.409L4.153,1.368a1.122,1.122,0,0,1,.765-.352,1.065,1.065,0,0,1,.749.361c.279.257.541.526.824.812.141.145.287.29.433.439l1.24,1.235a1.124,1.124,0,0,1,.391.775,1.124,1.124,0,0,1-.391.775c-.129.128-.258.261-.387.39-.387.39-.749.758-1.148,1.111l-.021.021a.808.808,0,0,0-.208.92c0,.012.008.021.012.033a9.747,9.747,0,0,0,1.46,2.366,16.613,16.613,0,0,0,4.01,3.639,5.961,5.961,0,0,0,.549.3,5.15,5.15,0,0,1,.458.249l.046.025a.872.872,0,0,0,.4.1.884.884,0,0,0,.62-.282l1.556-1.55a1.1,1.1,0,0,1,.761-.369,1.046,1.046,0,0,1,.736.369L19.52,15.23a1,1,0,0,1-.012,1.562c-.175.186-.358.365-.553.551a11.955,11.955,0,0,0-.869.9A2.288,2.288,0,0,1,16.3,19c-.071,0-.146,0-.216-.008A14.208,14.208,0,0,1,12.5,17.92S7.639,14.9,5.755,12.656a18.448,18.448,0,0,1-3.286-5.45A6.662,6.662,0,0,1,1.944,4.318Z"
                                                    transform="translate(814.089 39)"
                                                    fill="#fff"
                                                />
                                            </g>
                                        </svg>
                                    </a>
                                </div>
                            )}
                        </Col>
                        {!hideCard && (
                            <div className="bottom-text-wrap">
                                <Col sm={9} className={'cta'}>
                                    <div className="bottom-text-wrap__box">
                                        <img src="/images/static/logo.png" alt=""/>
                                    </div>
                                </Col>
                            </div>
                        )}
                    </Row>
                </Container>
            </StyledLube>
        </>
    )
}
const StyledLube = styled.section`
    position: relative;
    padding-top: 100px;
    padding-bottom: 120px;

    .hide {
        display: none;
    }

    h1 {
        color: #ffffff;
        margin-bottom: 30px;
    }

    h2 {
        color: #ffffff;
        margin: 0 0 90px 0;
    }

    .call {
        width: fit-content;

        a {
            position: relative;
            z-index: 2;
        }
    }

    @media (min-width: 1550px) {
        padding-top: 250px;
        padding-bottom: 250px;
        .bottom-text-wrap__box {
            padding-top: calc(76.5714%) !important;
            min-height: 300px !important;
            max-width: 30%;
            min-width: 400px !important;

            h4 {
                top: 50px !important;
                left: 50px !important;
                right: 50px !important;
            }

            .dc-btn {
                left: 50px !important;
                bottom: 50px !important;
            }
        }

        .bottom-text-wrap {
            bottom: -140px !important;
        }
    }

    p {
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        margin: 0;
        color: #ffffff;
    }

    .bottom-text-wrap {
        position: absolute;
        left: ${props => (props.offset ? props.offset - 30 : '0')}px;
        bottom: -110px;
        z-index: 9;

        &__box {
            background-color: #000000;
            padding-top: calc(268 / 270 * 100%);
            max-width: 25%;
            min-height: 220px;
            bottom: 0px;
            position: absolute;
            min-width: 320px;

            @media (min-width: 768px) and (max-width: 991px) {
                display: none;
            }

            h4 {
                font-family: ${hover};
                color: #ffffff;
                margin: 0;
                font-size: 20px;
                line-height: 28px;
                font-weight: 500;
                position: absolute;
                top: 40px;
                left: 40px;
                right: 40px;
            }

            .dc-btn {
                position: absolute;
                left: 40px;
                bottom: 40px;
            }
        }
    }

    @media (max-width: 1150px) {
        .bottom-text-wrap__box {
            h4 {
                font-size: 25px;
                line-height: 28px;
            }
        }
    }

    @media (max-width: 1020px) {
        .bottom-text-wrap {
            .col-sm-6 {
                min-width: 100% !important;
                margin: 0;

                p {
                    margin-bottom: 40px;
                }
            }
        }
    }

    @media (max-width: 767px) {
        padding: 125px 0 !important;
        .bottom-text-wrap {
            position: relative;
            left: unset;
            right: unset;
            bottom: unset;
            width: 100%;
            display: none;

            .col-sm-9 {
                padding-right: 0;
                padding-left: 0;
            }
        }

        .bottom-text-wrap__box {
            position: relative;
            left: unset;
            right: unset;
            bottom: unset;
            width: 100%;
            max-width: 100%;
            min-height: unset;
            height: auto !important;
            min-width: 100%;
            padding-top: 60%;
        }

        h2 {
            margin: 0;
        }
    }
    @media (max-width: 600px) {
        padding-top: 60px;
        h1 {
            margin-bottom: 38px;
            font-size: 32px;
            line-height: 36px;
            font-weight: 600;
        }

        .bottom-text-wrap .col-sm-3 {
            max-width: calc(100% - 50px);
            min-width: calc(100% - 50px);

            .bottom-text-wrap__box {
                //padding-top: calc(150 / 270 * 100%);
                height: 270px;
                padding-top: 0;

                h4 {
                    font-size: 32px;
                    line-height: 36px;
                }
            }
        }
    }

    @media (max-width: 350px) {
        .bottom-text-wrap .col-sm-3 {
            min-width: 100%;
        }
    }

    @media (min-width: 1500px) {
        .bottom-text-wrap__box {
            padding-top: calc(268 / 350 * 100%);
        }
    }
`

const StyleMobile = styled.section`
    display: none;

    .bottom-text-wrap {
        &__box {
            background-color: #000;

            h4 {
                font-family: ${hover};
                color: #ffffff;
                margin: 0;
                font-size: 20px;
                line-height: 28px;
                font-weight: 500;
                position: absolute;
                top: 40px;
                left: 40px;
                right: 40px;
            }

            .dc-btn {
                position: absolute;
                left: 40px;
                bottom: 40px;
            }
        }
    }

    @media (max-width: 767px) {
        display: block;

        .bottom-text-wrap {
            position: relative;
            left: unset;
            right: unset;
            bottom: unset;
            width: 100%;

            .col-sm-9 {
                padding-right: 0;
                padding-left: 0;
            }
        }

        .bottom-text-wrap__box {
            position: relative;
            left: unset;
            right: unset;
            bottom: unset;
            width: 100%;
            max-width: 100%;
            min-height: unset;
            height: auto !important;
            min-width: 100%;
            //padding-top: 60%;
            min-height: 200px;
        }
    }
`
export default React.memo(SpecialtyLube)
