import React from 'react'
import {Container} from 'react-bootstrap'
import styled from 'styled-components'
import TableList from './pricing/TableList'

const Table = ({data}) => {
    return (
        <StyledTable>
            <Container>
                <h1>Tint Options</h1>
                {
                    data?.posts?.list?.map(item => {
                        return (
                            <TableList items={item} />
                        )
                    })
                }
            </Container>
        </StyledTable>
    )
}

const StyledTable = styled.section`
    h1 {
        font-size: 40px;
        text-align: center;
        margin-top: 30px;
    }
`
export default Table
