import React from 'react'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'
import Title from '../Title'

const Note = () => {
  return (
    <StyledComponent>
      <Container className={'text-center'}>
        <Title margin={'0 auto'} text={'Note'} />
        <p>
          Julien Window Tinting in North East, MD, can be reached at{' '}
          <a href="tel:410-287-8468">
            <strong>410-287-8468</strong>
          </a>{' '}
          to learn more or to schedule a window tinting appointment.
        </p>
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  padding: 60px 0;

  p {
    padding-top: 30px;
  }
`

export default Note
