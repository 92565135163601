import { motion } from 'framer-motion'
import React, { useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import { apiEndPoints } from '../../api/network/apiEndPoints'
import { ApiParam } from '../../api/network/apiParams'
import { PageAnimation } from '../../components/animations/PageAnimation'
import CallToAction from '../../components/CallToAction'
import Note from '../../components/contact/Note'
import Gallery from '../../components/Gallery'
import InnerBanner from '../../components/InnerBanner'
import Video from '../../components/project/Video'
import {fetchGallery} from "../../api/redux/gallery";

const GalleryPage = ({ offset }) => {
    const dispatch = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS

        let api_params = {
            [ApiParam.TYPE]: 'slug',
            [ApiParam.VALUE]: 'gallery',
            [ApiParam.GET_SECTION]: 'yes',
            [ApiParam.IMAGE]: 'yes',
            [ApiParam.POST]: 'yes',
        }

        dispatch(fetchGallery([api_url, api_params]))

    }, [])

    const getData = useSelector((state) => state.gallery);

    const banner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "gallery-banner");
    const overview = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "gallery-overview");
    const gallery = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "gallery-image");
    const video = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "gallery-video");
    const noteData = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "gallery-note");

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Gallery | Elite Tinting</title>
                <meta
                    name="description"
                    content="Browse the Elite Tinting gallery to see our expert window tinting work for cars, homes, businesses, and boats. Explore the transformations and quality results we deliver to our clients."
                />
            </Helmet>

            <motion.div
                key={`1w83`}
                className="page-loader"
                exit="exit"
                animate="anim"
                variants={PageAnimation}
                initial="init"
            ></motion.div>

            <StyledComponent>
                <InnerBanner
                    data={banner}
                    img={banner?.images?.list?.[0]?.full_path}
                    srcSm={banner?.images?.list?.[0]?.full_path}
                />
                <CallToAction
                    padding
                    title={overview?.section_data?.subtitle}
                    desc={overview?.section_data?.short_desc}
                />
                {gallery && <Gallery data={gallery} />}

                <Video data={video} />
                <Note
                    title={'Call Today'}
                    desc={
                        noteData?.section_data?.description
                    }
                />
            </StyledComponent>
        </HelmetProvider>
    )
}

const StyledComponent = styled.section``

export default GalleryPage
