import {motion} from 'framer-motion'
import React, {useEffect} from 'react'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import {apiEndPoints} from '../../api/network/apiEndPoints'
import {ApiParam} from '../../api/network/apiParams'
import {PageAnimation} from '../../components/animations/PageAnimation'
import Table from '../../components/Table'
import {fetchPriceList} from "../../api/redux/priceList";
import InnerBanner from "../../components/InnerBanner";

const Pricing = () => {
    const dispatch = useDispatch()

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS

        let api_params = {
            [ApiParam.TYPE]: 'slug',
            [ApiParam.VALUE]: 'pricing-page',
            [ApiParam.GET_SECTION]: 'yes',
            [ApiParam.IMAGE]: 'yes',
            [ApiParam.POST]: 'yes',
        }

        dispatch(fetchPriceList([api_url, api_params]))
    }, [])

    const getData = useSelector((state) => state.priceList);

    const banner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "pricing-page-banner");
    const priceList = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === "price-list");

    console.log(priceList)

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Pricing | Elite Tinting</title>
                <meta
                    name="description"
                    content="Discover competitive pricing for Elite Tinting's professional window tinting services for automotive, residential, commercial, and marine needs. Get a transparent overview of our services and find the perfect tinting solution for your budget."
                />
            </Helmet>

            <motion.div
                key={`1w83`}
                className="page-loader"
                exit="exit"
                animate="anim"
                variants={PageAnimation}
                initial="init"
            ></motion.div>

            <StyledComponent>
                <InnerBanner
                    data={banner}
                    img={banner?.images?.list?.[0]?.full_path}
                    srcSm={banner?.images?.list?.[0]?.full_path}
                />
                <Table data={priceList} />
            </StyledComponent>
        </HelmetProvider>
    )
}

const StyledComponent = styled.section``

export default Pricing
